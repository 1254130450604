'use strict';

angular.module('informaApp')
    .directive('infMainTable', ["DrugIndicationService", "OmnitureSvc", "DiseaseNameHelper", "DrugHideSvc", "$rootScope", '$interval',
        function (DrugIndicationService, OmnitureSvc, DiseaseNameHelper, DrugHideSvc, $rootScope, $interval) {
            return {
                restrict: 'E',
                templateUrl: 'directives/inf-main-table/template.ptl.html',
                scope: {
                    hidePhases: "=",
                    tooltips: '=',
                    source: "=",
                    onLoad: "=",
                    selectedItems: "=",
                    clickOnTotalDuration: "=",
                    hiddenDrugs: "=",
                    startDate: '=',
                    endDate: '=',
                    onEmpty: '=',
                    isCompareMode: '=',
                    onSelectedItemsChange: '=',
                    onClickAddToComparison: '=',
                    onClickShowSummaryView: '=',
                    onClickTotalTransitions: '=',
                    durationIsMean: '='
                },
                getHeightByRowsCount: function (pinnedCount) {
                    return (pinnedCount * 102) + 64;
                },
                addBorder: function (notPinnedCount, pinnedCount) {
                    var padding = notPinnedCount > 0 ? 650 - (102) + "px" : 0;

                    $("tbody.original").css({
                        top: this.getHeightByRowsCount(pinnedCount) + "px",
                        paddingBottom: notPinnedCount
                    });
                },
                removeBorder: function () {
                    $("tbody.original").css("top", "");
                    $("tbody.original").css("paddingBottom", "");
                },
                setSelectedItems: function (scope) {
                    if (!scope.selectedItems) {
                        return;
                    }

                    scope.selectedItems.length = 0;
                    for (var i = 0; i < scope.source.length; i++) {
                        if (scope.source[i].selected) {
                            scope.selectedItems.push(scope.source[i]);
                        }
                    }
                },
                setEditable: function (source) {
                    if (source == null) {
                        return;
                    }

                    for (var i = 0; i < source.length; i++) {
                        source[i].editable = false;
                        source[i].name = DiseaseNameHelper.breakWord(source[i].name);
                    }
                },
                link: function (scope, element, attr) {
                    var sender = this;
                    scope.selectedAll = false;

                    scope.cutSearchName = cutSearchName;

                    scope.popupOptions = {};

                    scope.onTableLoad = function () {
                        scope.onLoad();
                    }

                    scope.durationOptions = {
                        inverted: true
                    };        

                    scope.selectAll = function () {
                        if (!scope.source) {
                            return;
                        }

                        scope.selectedAll = !scope.selectedAll;

                        for (var i = 0; i < scope.source.length; i++) {
                            scope.source[i].selected = scope.selectedAll;
                        }

                        sender.setSelectedItems(scope);
                    }

                    scope.select = function (row) {
                        row.selected = !row.selected;

                        updateSelectedAllFlag();

                        sender.setSelectedItems(scope);
                    }

                    scope.onExitToBiomedtracker = () => {
                        OmnitureSvc.exitToBiomedtracker.trackFromMainTable();
                    };

                    function updateSelectedAllFlag() {
                        if (!scope.source){
                            return;
                        }

                        scope.selectedAll = scope.source.every(function (x) {
                            return x.selected
                        });

                        scope.onSelectedItemsChange(scope.source.filter(function(x){
                            return x.selected;
                        }));
                    }

                    scope.pinRow = function (row) {
                        row.pinned = !row.pinned;

                        OmnitureSvc.trackPin(row.pinned ? row.name : "");

                        var pinnedCount = scope.source.filter(function (x) {
                            return x.pinned
                        }).length;

                        if (pinnedCount > 0) {
                            sender.addBorder(scope.source.length - pinnedCount, pinnedCount);
                        } else {
                            sender.removeBorder();
                        }

                        updateSource();
                    };

                    scope.breakeWord = function (word) {
                        return DiseaseNameHelper.breakWord(word);
                    };

                    function updateSource() {
                        scope.originalSource = _.filter(scope.source, function (row) {
                            return !row.pinned;
                        });
                        scope.pinnedSource = _.filter(scope.source, function (row) {
                            return row.pinned;
                        });

                        updateBottomMarginOfTable(scope.originalSource.length, scope.pinnedSource.length);
                    }

                    updateSource();

                    scope.editName = function (item) {
                        var index = scope.source.indexOf(item);

                        _.forEach(scope.source, function (x, i) {
                            x.editable = (index === i) ? !x.editable : false;

                            if (index === i) {
                                if (x.editable) {
                                    scope.recoverName = x.name;
                                } else {
                                    x.name = x.name || scope.recoverName;
                                    scope.recoverName = '';
                                }
                            }
                        });
                    };

                    scope.keyupHandler = function (event, item, index) {
                        if (event.which === 13) {
                            scope.editName(item, index);
                        }
                    };

                    scope.removeItem = function(row) {
                        _.remove(scope.source, x => x === row);
                        scope.closeIfEmpty();
                    };

                    scope.closeIfEmpty = function () {
                        if(scope.isCompareMode && scope.source && !scope.source.length) scope.onEmpty()
                    };

                    scope.$watch('source', function (a) {
                        updateSelectedAllFlag();
                        sender.removeBorder();

                        if (scope.source) {
                            scope.onLoad();
                        }
                    });

                    scope.$watch('source', function () {
                        updateSource();
                        updateSelectedAllFlag();

                        bindEvents(scope.source)

                        scope.totalDurationCallback = (id, name, drugIndicationIds) => {
                            if (scope.clickOnTotalDuration) {
                                scope.clickOnTotalDuration(id, name, drugIndicationIds);
                            }
                        };
                    }, true);

                    scope.$watch('durationIsMean', function (isMean) {
                        if (isMean) {
                            $rootScope.$broadcast('durationIsMean');
                        } else {
                            $rootScope.$broadcast('durationIsMedian');
                        }
                    });

                    function bindEvents(source) {
                        _.forEach(source, (item, index) => {
                            bindClickOnTotalTransitions(item, index);
                            bindClickOnLoa(item, index)
                        });
                    }

                    function bindClickOnLoa(item, index) {
                        if (item.clickOnLoa) {
                            item.loaCallbacks = {
                                args: {index: index},
                                clickOnValue: (phase) => {
                                    item.clickOnLoa(item.id, item.name, phase, item.drugIndicationIds);
                                }
                            }
                        }
                    }

                    function bindClickOnTotalTransitions(item, index) {
                        item.transitionsCallback = {
                            args: {index},
                            onTotalClick: (phase, args) => {
                                scope.onClickTotalTransitions(item.id, item.name, phase, item.drugIndicationIds)
                            }
                        };
                    }

                    function updateBottomMarginOfTable(notPinnedCount, pinnedCount) {
                        //the table's body position is absolute
                        //the height of table doesn't include the body size
                        //we have to to set bottom margin from js

                        var table = element.find('table');
                        var maxHeight = 650;

                        var tbodyHeight = sender.getHeightByRowsCount(notPinnedCount);
                        var pinnedHeight = pinnedCount == null ? 0 : sender.getHeightByRowsCount(pinnedCount);

                        table.css({'margin-bottom': pinnedHeight + (tbodyHeight > maxHeight ? maxHeight : tbodyHeight) + 'px'});
                    }

                    function cutSearchName(name) {
                        if (name) {
                            const maxNameLength = 20;
                            const cutSearchName =   name.slice(0, maxNameLength - 1)
                            return name === cutSearchName ? name : cutSearchName + '...';
                        }

                        return '';
                    }

                    this.setEditable(scope.source);
                    scope.closeIfEmpty();
                }
            }
        }]);
